@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap"; 

@import "partials/forms";

img {
    max-width:100%;
}

table {
    font-size: small;
}



